import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  titleBold: {
    fontWeight: 600,
  },
  statusGrid: {
    backgroundColor: "#b7b6b5"
  },
  button: {
    backgroundColor:"#0257a7 !important",
    marginLeft: "32px !important",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "20px !important"
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px !important"
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px !important"
    },
    "&:disabled": {
      backgroundColor: "#94979a !important",
      border: "none",
      color:"white !important"
    },
  },
  auditorButton: {
    backgroundColor:"#0257a7 !important",
    "&:disabled": {
      backgroundColor: "#94979a !important",
      border: "none",
      color:"white !important"
    },
  },
  popupStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70vw",
    overflow: "auto",
    backgroundColor: "#fff",
    border: '2px solid #000',
    boxShadow: 24,
    padding: '2%',
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "40vw",
      overflow: "auto",
      backgroundColor: "#fff",
      border: '2px solid #000',
      boxShadow: 24,
      padding: '2%',
      height: "300px",
      [theme.breakpoints.down("xs")]: {
        width: "60vw",

      },
    }
  },
  popupSmallStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40vw",
    overflow: "auto",
    backgroundColor: "#fff",
    border: '2px solid #000',
    boxShadow: 24,
    padding: '2%',
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "40vw",
      overflow: "auto",
      backgroundColor: "#fff",
      border: '2px solid #000',
      boxShadow: 24,
      padding: '2%',
      [theme.breakpoints.down("xs")]: {
        width: "60vw",

      },
    },
    [`${theme.breakpoints.down('md')} and (orientation: portrait)`]: {
      width: "60vw",
    }
  },
  popupStoreStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70vw",
    overflow: "auto",
    backgroundColor: "#fff",
    border: '2px solid #000',
    boxShadow: 24,
    padding: '2%',
    height: "400px",
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "40vw",
      overflow: "auto",
      backgroundColor: "#fff",
      border: '2px solid #000',
      boxShadow: 24,
      padding: '2%',
      height: "300px",
      [theme.breakpoints.down("xs")]: {
        width: "60vw",

      },
    }
  },
  dropZoneContainer: {
    "&.MuiDropzonePreviewList-removeButton": { top: "10px", right: "20px" }
  },
  popupbutton: {
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      margin: "7px !important"
    }
  },
  DropzoneAreaDisabled: {
    padding: "40px",
    pointerEvents: "none"
  },
  container: {
    display: 'flex'
  },
  
  two:{
    marginRight: 'auto'
  },
  previewChip:{
    "& .MuiDropzonePreviewList-image": {
      opacity: "unset",
    },
    "& .MuiDropzonePreviewList-image:hover": {
      opacity: "unset",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      pointerEvents: "none !important",  
      display: "none !important" 
    },
    "& .MuiDropzonePreviewList-removeButton:hover": {
      pointerEvents: "none !important"  
    },
    "& .MuiChip-deleteIcon": {
      pointerEvents: "none !important",  
      display: "none !important" 
    },
    "& .MuiChip-deleteIcon:hover": {
      pointerEvents: "none !important"  
    }
  },
}));