import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import TextField from "@mui/material/TextField";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ImageIcon from '@mui/icons-material/Image';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import HelpIcon from '@mui/icons-material/Help';
import 'react-dropzone-uploader/dist/styles.css'
import { DropzoneArea } from 'material-ui-dropzone';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import useStyles from "./style";
import Checkbox from '@mui/material/Checkbox';
import Badge from '@mui/material/Badge';
import { imageURL } from "../../base";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';


export default function QuestionListForm(props) {
    // console.log(props);

    var classes = useStyles();
    const [sectionId, setSectionId] = React.useState(0);
    const [openQuestion, setOpenQuestion] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [isValidateEnabled, setIsValidateEnabled] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openFullscreen, setOpenFullscreen] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(null);

    const roleType = localStorage.getItem("roleType");
    const auditStatus = localStorage.getItem("auditStatus");

    const Backdrop = React.forwardRef((props, ref) => {
        const { open, className, ...other } = props;
        return (
            <div
                className={clsx({ 'MuiBackdrop-open': open }, className)}
                ref={ref}
                {...other}
            />
        );
    });

    Backdrop.propTypes = {
        className: PropTypes.string.isRequired,
        open: PropTypes.bool,
    };


    const StyledModal = styled(Modal)`
        position: fixed;
        z-index: 1300;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    `;

    const StyledBackdrop = styled(Backdrop)`
        z-index: -1;
        position: fixed;
        inset: 0;
        background-color: rgb(0 0 0 / 0.3);
        -webkit-tap-highlight-color: transparent;
    `;

    const style = (theme) => ({
        width: 400,
        borderRadius: '12px',
        padding: '16px 32px 24px 32px',
        backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
        boxShadow: `0px 2px 24px ${theme.palette.mode === '#333333' ? '#000' : '#383838'}`,
    });


    function handleContentSection(section, quest, value, p) {
        // console.log(p)
        props.setIsContentOpen(true);
        setSectionId(section);
        setOpenQuestion(quest)
        if (localStorage.getItem('auditType') == '1') {
            if (value === "2" || value === "3" || value === "4" || p.valuation === "2" || p.valuation === "3" || p.valuation === "4") {
                setIsValidateEnabled(true)
            }
            else {
                setIsValidateEnabled(false)
            }
        }
    }


    function getInputImagefiles(props, index) {
        if (props?.selectedQuestion[index]?.images.length !== 0) {
            if (props?.selectedQuestion[index]?.images[0]?.fileName) {
                let array = [];
                props.selectedQuestion[index].images.forEach((item) => {
                    let fileName = item.fileName.split(".")
                    var file = new File([`${imageURL}/${item.imgfilepath}/${item.fileName}`], item.fileName, { type: `image/${fileName[1]}`, });
                    array.push(file);
                })
                // console.log(array);
                return array;
            }
            else {
                return props?.selectedQuestion[index]?.images;
            }

        }
        else
            if (props?.props?.values?.questions[index]?.images)
                return props?.props?.values?.questions[index]?.images;
            else
                return [];

    }

    function getInputVideosfiles(props, index) {
        if (props?.selectedQuestion[index]?.videos.length !== 0) {
            if (props?.selectedQuestion[index]?.videos[0]?.fileName) {
                let array = [];
                props.selectedQuestion[index].videos.forEach((item) => {
                    let fileName = item.fileName.split(".")
                    var file = new File([`${imageURL}/${item.videofilepath}/${item.fileName}`], item.fileName, {
                        type: `video/${fileName[1]}`,
                    });
                    array.push(file);
                })
                // console.log(array);
                return array;
            }
            else {
                return props?.selectedQuestion[index]?.videos;
            }
        }
        else
            if (props?.props?.values?.questions[index]?.videos)
                return props?.props?.values?.questions[index]?.videos;
            else
                return [];

    }

    function getComments(props, index) {
        if (props?.props?.values?.questions[index]?.comments && props?.props?.values?.questions[index]?.comments != null && props?.props?.values?.questions[index]?.comments != 'null')
            return props?.props?.values?.questions[index]?.comments;
    }

    function isRadioChecked(props, questionIndex, answerIndex) {
        if (props?.props?.values?.questions[questionIndex]?.valuation && props?.props?.values?.questions[questionIndex]?.valuation != null && props?.props?.values?.questions[questionIndex]?.valuation != 'null') {
            if (parseInt(props?.props?.values?.questions[questionIndex]?.valuation) === (answerIndex + 1)) {
                return true;
            }

        }
        else if (props?.selectedQuestion?.[questionIndex]?.valuation && props?.selectedQuestion?.[questionIndex]?.valuation != null && props?.selectedQuestion?.[questionIndex]?.valuation != 'null') {
            if (parseInt(props?.selectedQuestion?.[questionIndex]?.valuation) === (answerIndex + 1)) {
                return true;
            }
        }
        else return false;
    }

    function imagesBadgeCount(props, questionIndex) {
        return props?.selectedQuestion[questionIndex]?.images.length;
    }
    function videosBadgeCount(props, questionIndex) {
        return props?.selectedQuestion[questionIndex]?.videos.length;
    }
    function commentsBadgeCount(props, questionIndex) {
        if (props?.selectedQuestion[questionIndex]?.comments !== null) {
            if (props?.selectedQuestion[questionIndex]?.comments.length > 0)
                return 1;
            else
                return 0;
        }
        else
            return 0;
    }

    function isRadioDisabled(props, questionIndex, answerIndex) {
        if (localStorage.getItem('auditType') == '1') {
            if (roleType === "Reviewer" || auditStatus === "Audit Completed" || roleType === "Store User" || (localStorage.getItem("roleType") === "Food Inspection Auditor" && localStorage.getItem("auditStatus") === "Review In-progress")) {
                return true;
            }
            else {
                const ansInd = answerIndex + 1;
                if (ansInd === 1) {
                    if (props?.selectedQuestion?.[questionIndex]?.helpStandard === "N/A")
                        // props?.selectedQuestion?.[questionIndex]?.helpStandard === null || 
                        return true;
                }
                else if (ansInd === 2) {
                    if (props?.selectedQuestion?.[questionIndex]?.helpBelowstandard === "N/A")
                        // props?.selectedQuestion?.[questionIndex]?.helpBelowstandard === null || 
                        return true;
                }
                else if (ansInd === 3) {
                    if (props?.selectedQuestion?.[questionIndex]?.helpSerious === "N/A")
                        // props?.selectedQuestion?.[questionIndex]?.helpSerious === null || 
                        return true;
                }
                else if (ansInd === 4) {
                    if (props?.selectedQuestion?.[questionIndex]?.helpCritical === "N/A")
                        // props?.selectedQuestion?.[questionIndex]?.helpCritical === null || 
                        return true;
                }
                else
                    return false;
            }
        }
        else if (roleType === "Reviewer" || auditStatus === "Audit Completed" || (localStorage.getItem("roleType") === "Food Inspection Auditor" && localStorage.getItem("auditStatus") === "Review In-progress")) {
            return true;
        }
        else
            return false;
    }

    function handleCheckbox(event) {
        // console.log(event);
        if (localStorage.getItem('auditType') == '1') {
            props.setIsCheckedAll(event.target.checked ? true : false);
            if (event.target.checked) {
                props.selectedQuestion.forEach((q, index) => {
                    props.props.setFieldValue(`questions[${index}].valuation`, "5")
                    props.dynamicQuestionsList[index].valuation = "5";
                    props.setDynamicFinalAns((prevState) => {
                        // console.log(prevState);
                        const coppiedStateArray = [...prevState];
                        let isDataAlreadyPresentIndex = coppiedStateArray.findIndex((arrItem) => arrItem.questionnaireUID === props.dynamicQuestionsList[index].questionnaireUID)
                        if (isDataAlreadyPresentIndex !== -1) {
                            coppiedStateArray[isDataAlreadyPresentIndex].valuation = props.dynamicQuestionsList[index].valuation
                        } else {
                            coppiedStateArray.push(props.dynamicQuestionsList[index]);
                        }
                        // console.log(coppiedStateArray);
                        return coppiedStateArray;
                    });
                })
            }
            else {
                props.selectedQuestion.forEach((q, index) => {
                    props.props.setFieldValue(`questions[${index}].valuation`, "")
                    props.dynamicQuestionsList[index].valuation = "";
                    props.setDynamicFinalAns((prevState) => {
                        // console.log(prevState);
                        const coppiedStateArray = [...prevState];
                        let isDataAlreadyPresentIndex = coppiedStateArray.findIndex((arrItem) => arrItem.questionnaireUID === props.dynamicQuestionsList[index].questionnaireUID)
                        if (isDataAlreadyPresentIndex !== -1) {
                            coppiedStateArray[isDataAlreadyPresentIndex].valuation = props.dynamicQuestionsList[index].valuation
                        } else {
                            coppiedStateArray.push(props.dynamicQuestionsList[index]);
                        }
                        // console.log(coppiedStateArray);
                        return coppiedStateArray;
                    });
                })
            }
        } else if (localStorage.getItem('auditType') == '2') {
            props.setIsCheckedAll(event.target.checked ? true : false);
            if (event.target.checked) {
                props.selectedQuestion.forEach((q, index) => {
                    props.props.setFieldValue(`questions[${index}].valuation`, "4")
                    props.dynamicQuestionsList[index].valuation = "4";
                    props.setDynamicFinalAns((prevState) => {
                        // console.log(prevState);
                        const coppiedStateArray = [...prevState];
                        let isDataAlreadyPresentIndex = coppiedStateArray.findIndex((arrItem) => arrItem.questionnaireUID === props.dynamicQuestionsList[index].questionnaireUID)
                        if (isDataAlreadyPresentIndex !== -1) {
                            coppiedStateArray[isDataAlreadyPresentIndex].valuation = props.dynamicQuestionsList[index].valuation
                        } else {
                            coppiedStateArray.push(props.dynamicQuestionsList[index]);
                        }
                        // console.log(coppiedStateArray);
                        return coppiedStateArray;
                    });
                })
            }
            else {
                props.selectedQuestion.forEach((q, index) => {
                    props.props.setFieldValue(`questions[${index}].valuation`, "")
                    props.dynamicQuestionsList[index].valuation = "";
                    props.setDynamicFinalAns((prevState) => {
                        // console.log(prevState);
                        const coppiedStateArray = [...prevState];
                        let isDataAlreadyPresentIndex = coppiedStateArray.findIndex((arrItem) => arrItem.questionnaireUID === props.dynamicQuestionsList[index].questionnaireUID)
                        if (isDataAlreadyPresentIndex !== -1) {
                            coppiedStateArray[isDataAlreadyPresentIndex].valuation = props.dynamicQuestionsList[index].valuation
                        } else {
                            coppiedStateArray.push(props.dynamicQuestionsList[index]);
                        }
                        // console.log(coppiedStateArray);
                        return coppiedStateArray;
                    });
                })
            }
        }
        else if (localStorage.getItem('auditType') == '3') {
            props.setIsCheckedAll(event.target.checked ? true : false);
            if (event.target.checked) {
                props.selectedQuestion.forEach((q, index) => {
                    props.props.setFieldValue(`questions[${index}].valuation`, "3")
                    props.dynamicQuestionsList[index].valuation = "3";
                    props.setDynamicFinalAns((prevState) => {
                        // console.log(prevState);
                        const coppiedStateArray = [...prevState];
                        let isDataAlreadyPresentIndex = coppiedStateArray.findIndex((arrItem) => arrItem.questionnaireUID === props.dynamicQuestionsList[index].questionnaireUID)
                        if (isDataAlreadyPresentIndex !== -1) {
                            coppiedStateArray[isDataAlreadyPresentIndex].valuation = props.dynamicQuestionsList[index].valuation
                        } else {
                            coppiedStateArray.push(props.dynamicQuestionsList[index]);
                        }
                        // console.log(coppiedStateArray);
                        return coppiedStateArray;
                    });
                })
            }
            else {
                props.selectedQuestion.forEach((q, index) => {
                    props.props.setFieldValue(`questions[${index}].valuation`, "")
                    props.dynamicQuestionsList[index].valuation = "";
                    props.setDynamicFinalAns((prevState) => {
                        // console.log(prevState);
                        const coppiedStateArray = [...prevState];
                        let isDataAlreadyPresentIndex = coppiedStateArray.findIndex((arrItem) => arrItem.questionnaireUID === props.dynamicQuestionsList[index].questionnaireUID)
                        if (isDataAlreadyPresentIndex !== -1) {
                            coppiedStateArray[isDataAlreadyPresentIndex].valuation = props.dynamicQuestionsList[index].valuation
                        } else {
                            coppiedStateArray.push(props.dynamicQuestionsList[index]);
                        }
                        // console.log(coppiedStateArray);
                        return coppiedStateArray;
                    });
                })
            }
        }
    }

    function getClassname() {
        if (localStorage.getItem('auditType') == '3' || localStorage.getItem('auditType') == '4') {
            return classes.radioChecklistButton
        } else if ((localStorage.getItem('auditType') == '1' || localStorage.getItem('auditType') == '2') && localStorage.getItem('disableCheckBox') === 'true') {
            return classes.radioDisabledButton
        }
        else if ((localStorage.getItem('auditType') == '1' || localStorage.getItem('auditType') == '2') && localStorage.getItem('disableCheckBox') === 'false') {
            if(localStorage.getItem("roleType") === "Food Inspection Auditor" && localStorage.getItem("auditStatus") === "Review In-progress"){
                return classes.radioDisabledButton
            }else
            return classes.radioButton
        }
    }
    const handleClickChip = (file) => {
        // Open the fullscreen modal
        setSelectedFile(file);
        setOpenFullscreen(true);
    };

    const handleCloseFullscreen = () => {
        setOpenFullscreen(false);
        setSelectedFile(null); // Clear the selected file
    };
    return (
        <React.Fragment>
            {(localStorage.getItem('auditType') == '1' || localStorage.getItem('auditType') == '2' || localStorage.getItem('auditType') == '3') && localStorage.getItem('disableCheckBox') == 'false' ? (<FormControlLabel
                label="Not Applicable for all"
                sx={{
                    float: "right",
                    marginTop: '10px'
                }}
                control={
                    <Checkbox
                        checked={props.isCheckedAll}
                        onChange={handleCheckbox}
                    />} />) : null}
            <FieldArray name="questions">
                {() => (
                    <div>
                        {props.selectedQuestion.map((p, index) => {
                            const images = `questions[${index}].images`;
                            const videos = `questions[${index}].videos`;
                            const comments = `questions[${index}].comments`;
                            const valuation = `questions[${index}].valuation`;

                            return (
                                <div key={index}>
                                    <Box sx={{ width: '100%', backgroundColor: "#fff", borderRadius: "5px", padding: "1rem", marginBottom: "14px" }}>
                                        <Grid container rowSpacing={2} >
                                            <Grid item xs={12}>

                                                {p.questionCategory === "Normal" ?
                                                    (
                                                        <h3>
                                                            {index + 1}. {p.questionName}
                                                            {localStorage.getItem('auditType') == '1' ? (<Tooltip title="Help Content"><HelpIcon onClick={(event) => { handleContentSection(4, p.questionName, "0", p); handleOpen(); }} sx={{ color: "#2196f3", fontSize: '21px' }} /></Tooltip>) : null}
                                                        </h3>
                                                    ) :
                                                    (
                                                        <h3 style={{ color: "red" }}>
                                                            {index + 1}. {p.questionName}
                                                            {localStorage.getItem('auditType') == '1' ? (<Tooltip title="Help Content"><HelpIcon onClick={(event) => { handleContentSection(4, p.questionName, "0", p); handleOpen(); }} sx={{ color: "#2196f3", fontSize: '21px' }} /></Tooltip>) : null}
                                                        </h3>
                                                    )
                                                }

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    {props.responseList.map((ans, i) => {
                                                        return (
                                                            <Grid item lg={localStorage.getItem('auditType') == '3' || localStorage.getItem('auditType') == '4' ? 4 : 2} xs={localStorage.getItem('auditType') == '3' || localStorage.getItem('auditType') == '4' ? 4 : 3} key={i} className={localStorage.getItem('auditType') == '3' || localStorage.getItem('auditType') == '4' ? classes.gridChecklistSpace : classes.gridSpace}>
                                                                <FormControl>
                                                                    <InputLabel className={isRadioChecked(props, index, i) ? classes.radioLabelChecked : classes.radioLabel}>{ans.answerValue}</InputLabel>
                                                                    <Field type="radio" label={ans.answerValue} name={valuation} value={ans.statusUID} className={getClassname()}
                                                                        disabled={isRadioDisabled(props, index, i)}
                                                                        onChange={(e) => {
                                                                            props.props.setFieldValue(`questions[${index}].valuation`, e.target.value);
                                                                            props.dynamicQuestionsList[index].valuation = e.target.value;
                                                                            props.setDynamicFinalAns((prevState) => {
                                                                                // console.log(prevState);
                                                                                const coppiedStateArray = [...prevState];
                                                                                let isDataAlreadyPresentIndex = coppiedStateArray.findIndex((arrItem) => arrItem.questionnaireUID === props.dynamicQuestionsList[index].questionnaireUID)
                                                                                if (isDataAlreadyPresentIndex !== -1) {
                                                                                    coppiedStateArray[isDataAlreadyPresentIndex].valuation = props.dynamicQuestionsList[index].valuation
                                                                                } else {
                                                                                    coppiedStateArray.push(props.dynamicQuestionsList[index]);
                                                                                }
                                                                                // console.log(coppiedStateArray);
                                                                                return coppiedStateArray;
                                                                            });
                                                                            if (localStorage.getItem('auditType') == '1') {
                                                                                if (e.target.value === "2" || e.target.value === "3" || e.target.value === "4") {
                                                                                    handleContentSection(3, p.questionName, e.target.value, p);
                                                                                } else {
                                                                                    props.setIsContentOpen(false);
                                                                                }
                                                                            }
                                                                        }}
                                                                        checked={isRadioChecked(props, index, i)}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <hr></hr>
                                            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
                                                <Grid container >
                                                    <Grid item xs={12} sx={{ marginLeft: "auto", textAlign: "start", paddingRight: "0.75rem" }}>
                                                        <Tooltip title="Comments">
                                                            <Badge badgeContent={commentsBadgeCount(props, index)} color="error">
                                                                <AddCommentIcon onClick={() => { handleContentSection(3, p.questionName, "0", p) }} sx={{ color: "#0257a7", fontSize: '33px' }}>
                                                                </AddCommentIcon>
                                                            </Badge>
                                                        </Tooltip>
                                                        <Tooltip title="Add Images">
                                                            <Badge badgeContent={imagesBadgeCount(props, index)} color="error">
                                                                <ImageIcon onClick={() => { handleContentSection(1, p.questionName, "0", p) }} sx={{ color: "#0257a7", fontSize: '33px' }}>
                                                                </ImageIcon>
                                                            </Badge>
                                                        </Tooltip>
                                                        <Tooltip title="Add Videos">
                                                            <Badge badgeContent={videosBadgeCount(props, index)} color="error">
                                                                <PlayCircleIcon onClick={() => { handleContentSection(2, p.questionName, "0", p) }} sx={{ color: "#0257a7", fontSize: '33px' }}>
                                                                </PlayCircleIcon>
                                                            </Badge>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {props.isContentOpen ? <Box sx={{ width: '100%', backgroundColor: "white", borderRadius: "5px" }}>

                                                    {sectionId === 0 && (
                                                        <>
                                                            <Box sx={{ padding: "1rem", }}>
                                                                <Typography variant="h4" size="sm" >
                                                                    photo
                                                                </Typography>
                                                            </Box>

                                                        </>
                                                    )}
                                                    {(sectionId === 1 && openQuestion === p.questionName) && (
                                                        <>
                                                            <Box sx={{ padding: "1rem", }}>
                                                                <DropzoneArea
                                                                    acceptedFiles={['image/*']}
                                                                    clearOnUnmount={false}
                                                                    dropzoneClass={localStorage.getItem('disableCheckBox') == 'true' || (localStorage.getItem("roleType") === "Food Inspection Auditor" && localStorage.getItem("auditStatus") === "Review In-progress") ? classes.DropzoneAreaDisabled : classes.DropzoneArea}
                                                                    dropzoneProps={{
                                                                        disabled: localStorage.getItem('disableCheckBox') == 'true' || (localStorage.getItem("roleType") === "Food Inspection Auditor" && localStorage.getItem("auditStatus") === "Review In-progress") ? true : false,
                                                                    }}
                                                                    dropzoneText={"Drag and drop an max 3 images here or click"}
                                                                    onChange={(files) => {
                                                                        props.props.setFieldValue(`questions[${index}].images`, files);
                                                                        props.dynamicQuestionsList[index].images = files;
                                                                        props.setDynamicFinalAns((prevState) => {
                                                                            // console.log(prevState);
                                                                            const coppiedStateArray = [...prevState];
                                                                            let isDataAlreadyPresentIndex = coppiedStateArray.findIndex((arrItem) => arrItem.questionnaireUID === props.dynamicQuestionsList[index].questionnaireUID)
                                                                            if (isDataAlreadyPresentIndex !== -1) {
                                                                                coppiedStateArray[isDataAlreadyPresentIndex].images = props.dynamicQuestionsList[index].images
                                                                            } else {
                                                                                coppiedStateArray.push(props.dynamicQuestionsList[index]);
                                                                            }
                                                                            // console.log(coppiedStateArray);
                                                                            return coppiedStateArray;
                                                                        });
                                                                    }}
                                                                    filesLimit={3}
                                                                    maxFileSize={6000000}
                                                                    initialFiles={getInputImagefiles(props, index)}
                                                                    showPreviews={ true }
                                                                    showPreviewsInDropzone={ false }
                                                                    useChipsForPreview={true }
                                                                    previewText={props.dynamicQuestionsList[index].images.length > 0 ? "Uploaded files" : "No files uploaded"}
                                                                    previewGridProps={{
                                                                        container: {
                                                                            spacing: 2,
                                                                            direction: 'row',
                                                                        },
                                                                    }}
                                                                    previewChipProps={{
                                                                        classes: { root:(localStorage.getItem('disableCheckBox') == 'true'|| roleType === "Reviewer"|| roleType === "Store User" || (localStorage.getItem("roleType") === "Food Inspection Auditor" && localStorage.getItem("auditStatus") === "Review In-progress") ) && classes.previewChip },
                                                                        clickable: true,
                                                                        onClick: (event, file) => {
                                                                            const clickedFileName = event.target.innerText;
                                                                            console.log(props.dynamicQuestionsList[index].images)
                                                                            const sfile = props.dynamicQuestionsList[index].images.find((f) => f.name === clickedFileName);
                                                                            console.log(sfile);
                                                                            handleClickChip(sfile);
                                                                            event.preventDefault();
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>

                                                        </>
                                                    )}
                                                    {(sectionId === 2 && openQuestion === p.questionName) && (
                                                        <>
                                                            <Box sx={{ padding: "1rem", }}>
                                                                <DropzoneArea
                                                                    acceptedFiles={['video/*']}
                                                                    clearOnUnmount={false}
                                                                    dropzoneClass={localStorage.getItem('disableCheckBox') == 'true' || (localStorage.getItem("roleType") === "Food Inspection Auditor" && localStorage.getItem("auditStatus") === "Review In-progress") ? classes.DropzoneAreaDisabled : classes.DropzoneArea}
                                                                    dropzoneProps={{ disabled: localStorage.getItem('disableCheckBox') == 'true' || (localStorage.getItem("roleType") === "Food Inspection Auditor" && localStorage.getItem("auditStatus") === "Review In-progress") ? true : false }}
                                                                    dropzoneText={"Drag and drop an max 3 videos here or click"}
                                                                    onChange={(files) => {
                                                                        props.props.setFieldValue(`questions[${index}].videos`, files);
                                                                        props.dynamicQuestionsList[index].videos = files;
                                                                        props.setDynamicFinalAns((prevState) => {
                                                                            // console.log(prevState);
                                                                            const coppiedStateArray = [...prevState];
                                                                            let isDataAlreadyPresentIndex = coppiedStateArray.findIndex((arrItem) => arrItem.questionnaireUID === props.dynamicQuestionsList[index].questionnaireUID)
                                                                            if (isDataAlreadyPresentIndex !== -1) {
                                                                                coppiedStateArray[isDataAlreadyPresentIndex].videos = props.dynamicQuestionsList[index].videos
                                                                            } else {
                                                                                coppiedStateArray.push(props.dynamicQuestionsList[index]);
                                                                            }
                                                                            // console.log(coppiedStateArray);
                                                                            return coppiedStateArray;
                                                                        });
                                                                    }}

                                                                    filesLimit={3}
                                                                    maxFileSize={6000000}
                                                                    initialFiles={getInputVideosfiles(props, index)}
                                                                    showPreviews={true}
                                                                    showPreviewsInDropzone={false}
                                                                    useChipsForPreview={true }
                                                                    previewText={props.dynamicQuestionsList[index].videos.length > 0 ? "Uploaded files" : "No files uploaded"}
                                                                    previewGridProps={{
                                                                        container: {
                                                                            spacing: 2,
                                                                            direction: 'row',
                                                                        },
                                                                    }}
                                                                    previewChipProps={{
                                                                        classes: { root:( localStorage.getItem('disableCheckBox') == 'true' || roleType === "Reviewer"|| roleType === "Store User" || (localStorage.getItem("roleType") === "Food Inspection Auditor" && localStorage.getItem("auditStatus") === "Review In-progress") )&& classes.previewChip },
                                                                        clickable: true,
                                                                        onClick: (event, file) => {
                                                                            const clickedFileName = event.target.innerText;
                                                                            console.log(props.dynamicQuestionsList[index].videos)
                                                                            const sfile = props.dynamicQuestionsList[index].videos.find((f) => f.name === clickedFileName);
                                                                            handleClickChip(sfile);
                                                                            event.preventDefault();
                                                                        },
                                                                    }}

                                                                />
                                                            </Box>

                                                        </>
                                                    )}
                                                    {(sectionId === 3 && openQuestion === p.questionName) && (
                                                        <>
                                                            <Box sx={{ padding: "1rem", }}>
                                                                <TextField
                                                                    type="text"
                                                                    name="comments"
                                                                    disabled={localStorage.getItem('disableCheckBox') == 'true'|| (localStorage.getItem("roleType") === "Food Inspection Auditor" && localStorage.getItem("auditStatus") === "Review In-progress") ? true : false}
                                                                    defaultValue={p.comments}
                                                                    value={getComments(props, index)}
                                                                    label="Comment"
                                                                    fullWidth
                                                                    variant="standard"
                                                                    onChange={(e) => {
                                                                        props.props.setFieldValue(`questions[${index}].comments`, e.target.value);
                                                                        props.dynamicQuestionsList[index].comments = e.target.value;
                                                                        props.setDynamicFinalAns((prevState) => {
                                                                            // console.log(prevState);
                                                                            const coppiedStateArray = [...prevState];
                                                                            let isDataAlreadyPresentIndex = coppiedStateArray.findIndex((arrItem) => arrItem.questionnaireUID === props.dynamicQuestionsList[index].questionnaireUID)
                                                                            if (isDataAlreadyPresentIndex !== -1) {
                                                                                coppiedStateArray[isDataAlreadyPresentIndex].comments = props.dynamicQuestionsList[index].comments
                                                                            } else {
                                                                                coppiedStateArray.push(props.dynamicQuestionsList[index]);
                                                                            }
                                                                            // console.log(coppiedStateArray);
                                                                            return coppiedStateArray;
                                                                        });
                                                                    }}
                                                                />
                                                            </Box>

                                                            {isValidateEnabled ? (
                                                                <Typography sx={{ fontSize: "13px !important", color: "red" }}>
                                                                    *Please enter the findings
                                                                </Typography>) : null}
                                                        </>
                                                    )}
                                                    {(sectionId === 4 && openQuestion === p.questionName) && (
                                                        <>
                                                            <Box sx={{ padding: "1rem", }}>
                                                                <StyledModal
                                                                    aria-labelledby="unstyled-modal-title"
                                                                    aria-describedby="unstyled-modal-description"
                                                                    open={open}
                                                                    onClose={handleClose}
                                                                    slots={{ backdrop: StyledBackdrop }}
                                                                >
                                                                    <Box sx={style}>
                                                                        <h2 id="unstyled-modal-title">Standard</h2>
                                                                        <p id="unstyled-modal-description">{p.helpStandard}</p>
                                                                        <h2 id="unstyled-modal-title">Below Standard</h2>
                                                                        <p id="unstyled-modal-description">{p.helpBelowstandard}</p>
                                                                        <h2 id="unstyled-modal-title">Serious</h2>
                                                                        <p id="unstyled-modal-description">{p.helpSerious}</p>
                                                                        <h2 id="unstyled-modal-title">Critical / Not assured</h2>
                                                                        <p id="unstyled-modal-description">{p.helpCritical}</p>
                                                                    </Box>
                                                                </StyledModal>
                                                            </Box>
                                                        </>
                                                    )}
                                                </Box> : null}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            )
                        })}
                    </div>
                )}
            </FieldArray>
            <Dialog
                open={openFullscreen}
                onClose={handleCloseFullscreen}
                maxWidth="lg"
            >
                <DialogTitle id="fullscreen-dialog-title">Preview: {selectedFile?.name}</DialogTitle>
                <DialogContent>
                    {selectedFile?.type?.startsWith('image/') && (
                        <img
                            src={URL.createObjectURL(selectedFile)}
                            alt={selectedFile.name}
                            style={{ width: '80%', height: 'auto' }}
                        />
                    )}
                    {selectedFile?.type?.startsWith('video/') && (
                        <video width="80%" controls autoPlay={true} muted playsInline >
                            <source src={URL.createObjectURL(selectedFile)} />
                        </video>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFullscreen} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}