import React, { useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Tooltip from '@mui/material/Tooltip';
// styles
import useStyles from "./styles";
import { apiPost, apiFormDataPost } from "../../apiCommon";
import { config } from "../../config";

// components
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import { useHistory } from "react-router-dom";
import { format } from 'date-fns';
import { ThreeDots } from 'react-loader-spinner';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import localforage from 'localforage';
import { imageURL } from "../../base";
toast.configure({
  position: toast.POSITION.TOP_LEFT
});


const FoodAudit = localforage.createInstance({
  name: "IKEADB",
  storeName: "FoodAudit"
});
const nameValueGetter = (params) => {
  return params.data.firstName ? params.data.firstName + ' ' + params.data.lastName : null;
};

const dateValueGetter = (params) => {
  return params.data.auditDatetime ? format(new Date(params.data.auditDatetime), "yyyy-MMM-dd hh:mm a") : null;
};
const percentageValueGetter = (params) => {
  return params.data.questionCount ? ((params.data.answerCount / params.data.questionCount) * 100).toFixed() + '%' +
    '(Q - ' + params.data.questionCount + ',' + 'A - ' + params.data.answerCount + ')' : null;
};

export default function Dashboard(props) {
  const history = useHistory()
  var classes = useStyles();
  var [activeTabId, setActiveTabId] = useState(0);
  const [loading, setLoading] = useState(false);
  const roleType = localStorage.getItem("roleType");
  const [gridApi, setgridApi] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [storeValue, setStoreValue] = useState([]);
  const [isStoreDetailsPopup, setIsStoreDetailsPopup] = useState(false);
  const [columnDef, setcolumnDef] = useState([]);


  async function addnewForm() {
    if (localStorage.getItem('internetFlag') == 'false') {
      toast.error('You are not online so not able to start the audit');
      return;
    }
    await FoodAudit.setItem("storedAnswers", []);
    localStorage.setItem("localComments", "")
    history.push("/app/ui/addnewform");
  }

  async function editStoreDetails(data) {
    if (localStorage.getItem('internetFlag') == 'true') {
      // setStoreValue(data);
      // setIsStoreDetailsPopup(true);
      let postData = {
        auditUID: data.auditUID,
      }
      localStorage.setItem("auditUID", data.auditUID)
      localStorage.setItem('auditType', data.auditEntityUID);
      let response = await apiPost(config.editAuditData, postData);
      //console.log(response)
      await FoodAudit.setItem("storeData", response.data.data[0])
      await FoodAudit.setItem("rowValue", data)
      history.push({ pathname: "/app/ui/addnewform", state: { storeData: response.data.data[0], rowValue: data } })
    } else {
      toast.error("You are not online to proceed");
    }
  }

  async function editFunc(value, audit) {
    if (localStorage.getItem('internetFlag') == 'false') {
      toast.error('You are not online so not able to proceed');
      return;
    }
    const AUTH_SESSION_KEY = 'bixware_user';
    let userDetails = JSON.parse(sessionStorage.getItem(AUTH_SESSION_KEY));
    localStorage.setItem("auditStatus", value.auditStatus);
    localStorage.setItem("dateDifference", value.date_difference)
    localStorage.setItem("reviewEditStatus", value.reviewEditStatus)
    if (userDetails.user.roleUID === '7' || audit === 1 || userDetails.user.roleUID === '12') {
      localStorage.setItem('disableCheckBox', 'true');
    } else {
      localStorage.setItem('disableCheckBox', 'false');
    }
    localStorage.setItem("auditUID", value.auditUID)
    let data = {
      "userUID": value.userUID,
      "auditUID": value.auditUID,
      "auditEntityUID": value.auditEntityUID
    }
    setLoading(true);
    await FoodAudit.removeItem("answerresponseDetails");
    await FoodAudit.removeItem("questionDetails");
    await FoodAudit.removeItem("storedAnswers");
    let response = '';
    if (value.auditEntityUID == '2') {
      response = await apiPost(config.paAuditDetailsByID, data);
    } else {
      response = await apiPost(config.auditDetailsByID, data);
    }
    //console.log(response.data.data)
    localStorage.setItem("transactionUID", response.data.data.questionDetails[0].auditSubTypeQuestionDetails[0].auditSubtypeUIDDetails[0].transactionUID);
    const newFinalData = response.data.data.questionDetails.map((q, i) => {
      const data1 = q?.auditSubTypeQuestionDetails.map((q1, i1) => {
        const data2 = q1?.auditSubtypeUIDDetails.map((q2, i2) => {
          let imagesArr = [];
          let videosArr = [];
          q2.images.forEach((item) => {
            let objectObj = `${imageURL}/${item.imgfilepath}/${item.fileName}`
            imagesArr.push(objectObj);
          })
          q2.videos.forEach((item) => {
            let objectObj = `${imageURL}/${item.videofilepath}/${item.fileName}`
            videosArr.push(objectObj);
          })
          return {
            ...q2,
            "valuation": q2.valuation,
            "comments": q2.comments,
            "images": imagesArr,
            "videos": videosArr,
            "standard": ""
          }
        })

        return {
          "auditSubtypeName": q1.auditSubtypeName,
          "auditSubtypeUID": q1.auditSubtypeUID,
          "auditSubtypeUIDDetails": data2
        }

      })
      return {
        "auditTypeUID": q.auditTypeUID,
        "auditTypeName": q.auditTypeName,
        "auditSubTypeQuestionDetails": data1
      }
    })
    await FoodAudit.setItem("questionDetails", newFinalData)
    await FoodAudit.setItem("answerresponseDetails", response.data.data.answerresponseDetails)
    localStorage.setItem('newAudit', 'false');
    localStorage.setItem('auditType', value.auditEntityUID);
    localStorage.setItem('actionPlan', response.data.data.questionDetails[0].auditSubTypeQuestionDetails[0].auditSubtypeUIDDetails[0].actionplanComments);
    localStorage.setItem('reviewBy', response.data.data.questionDetails[0].auditSubTypeQuestionDetails[0].auditSubtypeUIDDetails[0].reviewBy);
    localStorage.setItem('strictComments', response.data.data.questionDetails[0].auditSubTypeQuestionDetails[0].auditSubtypeUIDDetails[0].strictlocalComments);
    localStorage.setItem('closingMeetingComments', response.data.data.questionDetails[0].auditSubTypeQuestionDetails[0].auditSubtypeUIDDetails[0].closingmeetingAttendees);
    localStorage.setItem('closingMeetingAttendees', response.data.data.questionDetails[0].auditSubTypeQuestionDetails[0].auditSubtypeUIDDetails[0].closingmeetingAttendees);
    await FoodAudit.setItem("storedAnswers", []);
    setLoading(false);
    if ((localStorage.getItem("roleType") === "Reviewer" || localStorage.getItem("roleType") === "Store User") && localStorage.getItem('auditType') == '1') {
      actionPlanData();
    }
    history.push({ pathname: "/app/ui/questionnaire", state: { data: response.data, from: "dashboard" } })

  }

  async function getauditlist() {
    let data =
    {
      "userUID": localStorage.getItem('userUID')
    }

    const response = await apiPost(config.getAuditList, data);
    if (response.data.status == true) {
      // console.log(response)
      return response.data;
    }

  }
  useEffect(() => {
    (async () => {
      const newData = await getauditlist();
      if (newData.length != 0) {
        setrowData(newData.data);
        setcolumnDef([
          {
            headerName: 'Audit ID',
            field: 'auditUID',
            width: 100,
            colId: 'auditUID',
          },
          {
            valueGetter: nameValueGetter,
            headerName: 'User Name',
            width: 180,
            colId: 'userName',
          },
          {
            headerName: 'Store Name',
            field: 'storeName',
            width: 150,
            colId: 'storeName',
          },
          {
            headerName: 'Country',
            field: 'country',
            width: 150,
            colId: 'country',
          },
          {
            headerName: 'Audit Type',
            field: 'auditEntityName',
            width: 150,
            colId: 'auditEntityName',
          },
          {
            headerName: 'Audit Date',
            valueGetter: dateValueGetter,
            width: 200,
            colId: 'auditDate',
          },
          {
            headerName: 'Status',
            field: 'auditStatus',
            colId: 'auditStatus',
            width: 150,
          },
          {
            headerName: 'Percentage',
            valueGetter: percentageValueGetter,
            width: 200,
            colId: 'percentage',
          },
          {
            headerName: 'Action',
            field: 'id',
            width: 120,
            colId: 'id',
            cellRenderer: (params) => (params.data.auditStatus === "Audit Completed" ? (<>
              <Tooltip title="View">
                <IconButton onClick={(e) => { editFunc(params.data, 1) }}><VisibilityIcon /></IconButton>
              </Tooltip>
              {roleType !== "Reviewer" && roleType !== "Store User" ? (
                <>
                  {params.data.auditStatus === "Audit Completed" ? (
                    <>
                      <IconButton disabled={true}
                        onClick={(e) => { editStoreDetails(params.data) }}><EditNoteIcon /></IconButton>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Edit Audit Details">
                        <IconButton
                          onClick={(e) => { editStoreDetails(params.data) }}><EditNoteIcon /></IconButton>
                      </Tooltip>
                    </>
                  )}
                </>
              ) : null}


            </>) : (<>
              <Tooltip title="Edit">
                <IconButton onClick={(e) => { editFunc(params.data, 2) }}><EditIcon /></IconButton>
              </Tooltip>
              {roleType !== "Reviewer" && roleType !== "Store User" ? (
                <>
                  {params.data.auditStatus === "Audit Completed" ? (
                    <>
                      <IconButton disabled={true}
                        onClick={(e) => { editStoreDetails(params.data) }}><EditNoteIcon /></IconButton>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Edit Audit Details">
                        <IconButton
                          onClick={(e) => { editStoreDetails(params.data) }}><EditNoteIcon /></IconButton>
                      </Tooltip>
                    </>
                  )}
                </>
              ) : null}

            </>)
            )
          }
        ]);
        try {
          localStorage.setItem('auditUID', newData.data[0].auditUID);
        }
        catch (error) {
          localStorage.setItem('auditUID', '');
        }
      }
      //setrowData(newData.data.reverse())
    })();
    async function fetchData() {
      let data = {
        "userUID": localStorage.getItem("userUID"),
        "auditEntityUID": localStorage.getItem("auditEntityUID"),
        "storeUID": localStorage.getItem("storeUID")
      }
      const response = await apiPost(config.getReviewer, data);
      const reviewerValues = [];
      response.data.data.user.forEach((value) => {
        reviewerValues.push({ "userName": value.userName, "userUID": value.userUID });
      });
      await FoodAudit.setItem("reviewerValues", reviewerValues)
    }
    if (roleType !== "Store User") {
      fetchData();
    }
    localStorage.setItem("auditStatus", "")


  }, [])


  async function actionPlanData() {
    let data = {
      "auditUID": localStorage.getItem("auditUID")
    }
    await FoodAudit.setItem("actPlanRowData", [])
    const reviewerComments = await apiPost(getApiName(localStorage.getItem('auditType')), data);
    if (reviewerComments.data.message === 'Success') {
       console.log(reviewerComments.data.data);
      // setActPlanRowData(reviewerComments.data.data)
      await FoodAudit.setItem("actPlanRowData", reviewerComments.data.data)
    }
  }

  const onGridReady = (params) => {
    setgridApi(params.api);
    // params.api.sizeColumnsToFit();
    // window.onresize = () => {
    //   params.gridApi.sizeColumnsToFit();
    // }
    params.api.sizeColumnsToFit();
  }

  function getApiName(value) {
    switch (value) {
      case "1":
        return (
          config.getfoodreviewquestions
        );
      case "2":
        return (
          config.getpropertyreviewquestions
        );
      case "3":
        return (
          config.getdmreviewquestions
        );
      case "4":
        return (
          config.getsecurityreviewquestions
        );
      default:
        throw new Error("Unknown step");
    }
  }

  return (
    <>
      {loading ? (
        <>
          <ThreeDots

            color="#00BFFF"
            height={100}
            width={100}
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "13rem"
            }}
          />
        </>
      ) : (
        <>
          <Grid container sx={{ paddingTop: "70px", }}>
            <Grid item lg={8} md={8} sm={6} xs={12} sx={{ textAlign: 'start', paddingLeft: '0', }}>
              <PageTitle title="Dashboard" />
            </Grid>
            {roleType !== "Reviewer" && roleType !== "Store User" ? (
              <Grid item lg={4} md={4} sm={6} xs={12} sx={{ textAlign: 'end', paddingRight: '0', paddingBottom: '15px' }}>
                <Button
                  classes={{ root: classes.button }}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={addnewForm}
                >
                  Start New Audit
                </Button>
              </Grid>
            ) : null}
          </Grid>
          <Box style={{ width: '100%', backgroundColor: "#0257a7", borderRadius: "5px", }}>
            <Box style={{ borderBottom: 2, borderColor: 'divider' }}>
              <Tabs
                value={activeTabId}
                onChange={(e, id) => setActiveTabId(id)}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="My Audits" classes={{ root: classes.tab }} />
                {/* <Tab label="Upcoming" classes={{ root: classes.tab }} />
                  <Tab label="My Actions" classes={{ root: classes.tab }} /> */}
              </Tabs>
            </Box>
            {activeTabId === 0 && (
              <>
                <div className="ag-theme-alpine" id="aggridheight" style={{ width: '100%', border: 'none !important' }}>

                  <AgGridReact
                    defaultColDef={{
                      editable: false,
                      //  enableRowGroup: true,
                      //  enablePivot: true,
                      //  enableValue: true,

                      minWidth: 50,
                      cellStyle: { textAlign: 'left' }
                    }}
                    //  suppressRowClickSelection={true}
                    //  groupSelectsChildren={true}
                    //debug={true}
                    //  rowSelection={'multiple'}
                    // /*  rowGroupPanelShow={'always'} */
                    //  pivotPanelShow={'always'}
                    //  enableRangeSelection={true}
                    // pagination={true}
                    onGridReady={onGridReady}
                    columnDefs={columnDef}
                    rowData={rowData}
                  // paginationPageSize={rowData?.length > 6 ? 6 : rowData?.length}

                  />
                </div>
              </>
            )}
            {activeTabId === 1 && (
              <>
                <Box sx={{ padding: "1rem", }}>
                  <Typography variant="h4" size="sm" sx={{ color: "#f1f2f4", }}>
                    No planned schedules available
                  </Typography>
                </Box>

              </>
            )}
            {activeTabId === 2 && (
              <>
                <Box sx={{ padding: "1rem", }}>
                  <Typography variant="h4" size="sm" sx={{ color: "#f1f2f4", }}>
                    There are no actions to be performed at this moment!
                  </Typography>
                </Box>
              </>
            )}
          </Box>

        </>
      )}



    </>
  );
}
